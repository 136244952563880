// Custom SearchBox with throttling
// https://www.algolia.com/doc/guides/building-search-ui/going-further/improve-performance/react/
import React, { Component } from 'react';
import { stl } from "@algolia/satellite";
import MagnifierIcon from './icon-magnifier.svg';

export class SearchBox extends Component {
  timerId = null;

  state = {
    value: this.props.currentRefinement,
  };

  onChangeDebounced = (event) => {
    const { refine, delay } = this.props;
    const value = event.currentTarget.value;

    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => refine(value), delay);

    this.setState(() => ({
      value,
    }));
  };

  render() {
    const { value } = this.state;

    return (
      <input
        value={value}
        onChange={this.onChangeDebounced}
        className={stl`pr-4 pl-16 py-2 bg-grey-100 mt-4 mb-2 appearance-none text-2xl w-full outline-none selection:text-red bg-no-repeat bg-left`}
        style={{
          backgroundImage: `url(${MagnifierIcon})`,
          backgroundPositionX: '1rem',
        }}
        placeholder="Rechercher..." />
    );
  }
}
