import React from 'react';
import {
  Index,
  Hits, Configure, Pagination, connectStateResults, Stats
} from 'react-instantsearch-dom';
import { stl } from "@algolia/satellite";
import { HitCard } from './HitCard';
import { HitText } from './HitText';
import './HitList.css';

export function HitList(props) {

  var hitsPerPage = (props.expanded)? 16 : (props.type == 'card' ? 4 : 6);

  const Results = connectStateResults(
    ({ searchState, searchResults, children }) =>
      searchResults && searchResults.nbHits !== 0 ? (
        children
      ) : (
        <div>Pas de résultat pour "{searchState.query}".</div>
      )
  );

  return (
    <Index indexName={props.indexName}>
      <div className={stl`relative`}>

        <Stats className={stl`absolute right-0 top-3 md:top-7`} translations={{
          stats(nbHits, processingTimeMS, nbSortedHits, areHitsSorted) {
            return (nbHits > 1)?
              `${nbHits.toLocaleString()} résultats`
              : `${nbHits.toLocaleString()} résultat`
          },
        }} />

        <h2 className={'dashed-title ' + stl`text-grey text-accent font-bold mb-8 text-2xl md:text-3xl border-b`}>{props.title}</h2>
        <Configure hitsPerPage={ hitsPerPage } />
        <Results>
          <Hits hitComponent={props.type == 'card' ? HitCard : HitText}
            className={((props.hideContent)? 'hide-content' : '')
            + ' '
            + ((props.hideBreadcrumb)? 'hide-breadcrumb' : '')
            + ' layout-' + props.type } />
        </Results>
        <Pagination />
        {props.link && <a className={'btn-primary is-arrowed ' + stl`mt-4 md:mt-0 md:absolute md:bottom-0 md:right-0`} href={props.link}>
          Voir plus
        </a>}
      </div>
    </Index>
  );
}
