import React from 'react';
import {
  Highlight,
  Snippet
} from 'react-instantsearch-dom';
import { stl } from "@algolia/satellite";
import {
  Tag,
  CardMeta,
  ymdToPrettyDate,
  domainColors,
} from './App';

export function HitCard(props) {

  let domain = props.hit.domain;
  let extraClass = (domain)? domainColors[domain] : '';

  return (
    <a href={props.hit.url.replace('http://hearc.local', 'https://www.he-arc.ch')} className={extraClass + ' ' + stl`block h-full text-black no-underline hover:no-underline overflow-hidden shadow-flat border border-accent transition-shadow hover:shadow-z300`}>
      <div className={stl`block h-24 w-full bg-cover bg-center`} style={ (props.hit.image) ? {
        // TODO: remove
        backgroundImage: `url(${props.hit.image.replace('http://hearc.local', 'https://www.he-arc.ch')})`
      } : {} }></div>
      <div className={stl`overflow-hidden p-3`}>
        <div className={stl`flex justify-between`}>
          <div className={stl`mb-2 space-x-2`}>
            {domain &&
              <Tag name={domain} />}
            {props.hit.training_category &&
              <Tag name={props.hit.training_category} />}
          </div>
          <div className={stl`mb-2 space-x-2`}>
            {props.hit.date_from &&
              <CardMeta name={
                (props.hit.date_from != props.hit.date_to)?
                (ymdToPrettyDate(props.hit.date_from) + ' - ' + ymdToPrettyDate(props.hit.date_to))
                : ymdToPrettyDate(props.hit.date_from)
              } />}
            {props.hit.publish_date &&
              <CardMeta name={ymdToPrettyDate(props.hit.publish_date)} />}
          </div>
        </div>
        <h2 className={stl`text-md font-bold inline shadow-line`}>{props.hit.title}</h2>
        <div className={stl`text-secondary text-base`}>
          <Snippet attribute="seo_description" separator="…" hit={props.hit} />{' '}
          <Snippet className='entry-content' attribute="content" separator="…" hit={props.hit} />
        </div>
      </div>
    </a>
  );
}
