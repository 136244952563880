import React from 'react';
import {
  Highlight,
  Snippet,
} from 'react-instantsearch-dom';
import { stl } from "@algolia/satellite";
import {
  Tag,
  CardMeta,
  domainColors,
} from './App';

import SpeakerIcon from './icon-speaker.svg';
import PlayIcon from './icon-play.svg';
import SheetIcon from './icon-sheet.svg';

export function HitText(props) {

  if (props.hit.years) {
    yearsConcat = 'test';
    let min = Math.min(...props.hit.years);
    let max = Math.max(...props.hit.years);
    var yearsConcat = min;
    if (max > min) {
      yearsConcat = min + ' - ' + max;
    }
  }

  var breadcrumbItems = null;
  if (props.hit.breadcrumb) {
    breadcrumbItems = props.hit.breadcrumb.split(' > ');
  }

  let domain = props.hit.domain;
  let extraClass = (domain)? domainColors[domain] : '';

  let sourceType = props.hit.source_type;
  var sourceTypeIcon = null;
  if (sourceType == 'audio') {
    sourceTypeIcon = SpeakerIcon;
  } else if (sourceType == 'video') {
    sourceTypeIcon = PlayIcon;
  } else if (sourceType) {
    sourceTypeIcon = SheetIcon;
  }

  return (
    <a href={props.hit.url.replace('http://hearc.local', 'https://www.he-arc.ch')} className={extraClass + ' ' + stl`block h-full text-black no-underline hover:no-underline overflow-hidden md:pr-2`}>
      <div className={stl`overflow-hidden`}>
        <div className={stl`font-bold -mb-1 md:mr-6`}>
          <h2 className={stl`text-md font-bold inline mr-2 text-lg`}>{props.hit.title}</h2>
          <div className={stl`inline-flex items-center space-x-1 transform -translate-y-0.5`}>
            {domain &&
              <Tag name={domain} size="s" />}
            {props.hit.training_category &&
              <Tag name={props.hit.training_category} size="s" />}
            {sourceType &&
              <img src={sourceTypeIcon} alt={sourceType} className={stl`inline-block h-5 w-auto`} />}
          </div>
          <div className={stl`flex flex-row`}>
            {props.hit.years &&
              <CardMeta name={yearsConcat} size="s" />}
          </div>
        </div>
        {breadcrumbItems &&
          <div className={'entry-breadcrumb ' + stl`flex flex-row mt-1`}>
            {breadcrumbItems.map((item, i) => {
              return (
                <div key={i}>
                  <span className={stl`text-secondary text-sm`}>{ item }</span>
                  {(i < breadcrumbItems.length - 1) && <span className={stl`text-grey-500 text-sm mx-1`}>›</span>}
                </div>
              )
            })}
          </div>
        }
        <div className={stl`text-base mt-1.5`}>
          <Snippet attribute="seo_description" separator="…" hit={props.hit} />{' '}
          <Snippet className='entry-content' attribute="content" separator="…" hit={props.hit} />
        </div>
      </div>
    </a>
  );
}
