import qs from 'qs';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Configure,
  DynamicWidgets,
  Panel,
  RefinementList,
  Menu,
  connectSearchBox,
  connectCurrentRefinements,
} from 'react-instantsearch-dom';
import '@algolia/autocomplete-theme-classic/dist/theme.css';
//import './App.css';
import './hearc-colors.css';
import { /*Button,*/ stl } from "@algolia/satellite";

import { Autocomplete } from './Autocomplete';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { HitList } from './HitList';
import { SearchBox } from './SearchBox';

const searchClient = algoliasearch('YSPI6B6OLQ', '4ce685d440b9f3bae5d23eb87b8e5c93');
export const domainColors = {
  "HE-Arc": "theme-he-arc",
  "Conservation-restauration": "theme-conservation-restauration",
  "Gestion": "theme-gestion",
  "Ingénierie": "theme-ingenierie",
  "Santé": "theme-sante",
}

function createURL(searchState) {
  return qs.stringify(searchState, { addQueryPrefix: true });
}

function searchStateToUrl({ location }, searchState) {
  if (Object.keys(searchState).length === 0) {
    return '';
  }

  return `${location.pathname}${createURL(searchState)}`;
}

function urlToSearchState({ search }) {
  return qs.parse(search.slice(1));
}

function App() {
  const [searchState, setSearchState] = useState(() =>
    urlToSearchState(window.location)
  );
  const timerRef = useRef(null);

  useEffect(() => {
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      window.history.pushState(
        searchState,
        null,
        searchStateToUrl({ location: window.location }, searchState)
      );
    }, 400);
  }, [searchState]);

  const [selectedTab, setSelectedTab] = useState(searchState.tab || 0);
  const [showFilters, setShowFilters] = useState(false);
  const [showContentTypesSwitcher, setShowContentTypesSwitcher] = useState(false);

  const onSubmit = useCallback(({ state }) => {
    setSearchState((searchState) => ({
      ...searchState,
      query: state.query,
    }));
  }, []);

  const onReset = useCallback(() => {
    setSearchState((searchState) => ({
      ...searchState,
      query: '',
    }));
  }, []);

  const plugins = useMemo(() => {
    const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
      key: 'search',
      limit: 3,
      transformSource({ source }) {
        return {
          ...source,
          onSelect(params) {
            setSearchState((searchState) => ({
              ...searchState,
              query: params.item.label,
            }));
          },
        };
      },
    });

    return [
      recentSearchesPlugin,
      createQuerySuggestionsPlugin({
        searchClient,
        indexName: 'global_query_suggestions',
        getSearchParams() {
          return recentSearchesPlugin.data.getAlgoliaSearchParams({
            hitsPerPage: 5,
          });
        },
        transformSource({ source }) {
          return {
            ...source,
            onSelect(params) {
              setSearchState((searchState) => ({
                ...searchState,
                query: params.item.query,
              }));
            },
          };
        },
      }),
    ];
  }, []);

  const contentTypes = [
    {
      name: "Tout",
      index_name: "he-arc_training"
    },
    {
      name: "Pages",
      index_name: "page",
      layout: "text",
      hide_tab: true
    },
    {
      name: "Formations",
      index_name: "he-arc_training",
      layout: "text"
    },
    {
      name: "Actualités",
      index_name: "post",
      layout: "card",
      external_link: "https://www.he-arc.ch/he-arc/actualite-agenda/",
      hide_breadcrumb: true
    },
    {
      name: "Agenda",
      index_name: "he-arc_event",
      layout: "card",
      external_link: "https://www.he-arc.ch/he-arc/actualite-agenda/",
      hide_content: true,
      hide_breadcrumb: true
    },
    {
      name: "Projets de recherche",
      index_name: "he-arc_project",
      layout: "text",
      external_link: "https://www.he-arc.ch/projets-recherche/",
      hide_breadcrumb: true
    },
    {
      name: "Projets d\'étudiants",
      index_name: "he-arc_work",
      layout: "text",
      external_link: "https://www.he-arc.ch/projets-etudiants/",
      hide_breadcrumb: true
    },
    {
      name: "Prestations de services et équipements",
      index_name: "he-arc_equipment",
      layout: "text",
      external_link: "https://www.he-arc.ch/prestations-services-equipements/",
      hide_content: true,
      hide_breadcrumb: true
    },
    {
      name: "Publications",
      index_name: "he-arc_publication",
      layout: "text",
      external_link: "https://www.he-arc.ch/publications/",
      hide_content: true,
      hide_breadcrumb: true
    },
    {
      name: "Revue de presse",
      index_name: "he-arc_press-review",
      layout: "text",
      external_link: "https://www.he-arc.ch/presse/",
      hide_breadcrumb: true
    },
  ];

  const refinementLabels = {
    domain: "Domaine : ",
    training_category: "Type de formation : ",
  }

  require("./App.css");

  return (
    <div>
      {/*<header className="header">
        <div className={'container ' + stl`md:flex items-center justify-between`}>
            <h1 className="header-title">
              <a href="/">Démo frontend Algolia</a>
            </h1>
          <div className={stl`mt-2 md:mt-0 md:ml-4`}>
            <Autocomplete
              placeholder="Recherche"
              detachedMediaQuery="none"
              initialState={{
                query: searchState.query,
              }}
              openOnFocus={true}
              onSubmit={onSubmit}
              onReset={onReset}
              plugins={plugins}
            />
          </div>
        </div>
      </header>*/}

      <div className="app container">

        <ul className={(showContentTypesSwitcher)? stl`block absolute left-2 right-2 top-32 z-50 bg-grey-50 pt-2 px-2 pb-4 w-auto border` : stl`hidden` + ' ' + stl`px-4 md:px-0 py-2 md:py-0 md:bg-white md:flex md:flex-row md:flex-wrap mb-4`}>
          {contentTypes.map((contentType, i) => {
            return (contentType.hide_tab)? '' : (
              <Tab key={i} name={contentType.name} active={selectedTab == i} onClick={() => {
                setShowContentTypesSwitcher(false);
                setSelectedTab(i);
                setSearchState((searchState) => {
                  let indices = searchState['indices'];
                  if (indices) {
                    let selectedIndice = indices[ contentTypes[selectedTab].index_name ];
                    if (selectedIndice) {
                      searchState['indices'][ contentTypes[selectedTab].index_name ]['page'] = 1;
                    }
                  }
                  searchState['tab'] = i;
                  return searchState;
                });
              }}></Tab>
            )
          })}
        </ul>

        <InstantSearch
          searchClient={searchClient}
          indexName={contentTypes[selectedTab].index_name}
          searchState={searchState}
          onSearchStateChange={setSearchState}
          createURL={createURL}
        >

          <div className={stl`mb-0 md:mb-6`}>

              <DebouncedSearchBox delay={100} />
              {/*<SearchBox className={stl`mt-4 mb-2`} translations={{
                placeholder: 'Rechercher...',
              }} />*/}

              <div className={stl`flex md:hidden space-x-2`}>
                <button className={'dropdown ' + stl`flex-1 block`} onClick={() => {
                  setShowContentTypesSwitcher(!showContentTypesSwitcher);
                  setShowFilters(false);
                }}>
                  {contentTypes[selectedTab].name}
                </button>

                <button className={'dropdown ' + stl`flex-1 block`} onClick={() => {
                  setShowFilters(!showFilters);
                  setShowContentTypesSwitcher(false);
                }}>
                  Filtres
                </button>
              </div>

              <div className={stl`mt-2`}>
                <CustomCurrentRefinements
                  transformItems={items =>
                    items.map((item) => ({
                      ...item,
                      label: refinementLabels[item.attribute] || item.label
                    }))
                  } />
              </div>
          </div>

          <div className={stl`md:flex` }>

            <div className={ (showFilters)? stl`block absolute left-2 right-2 z-50 bg-grey-50 pt-2 px-2 pb-4 w-auto border` : stl`hidden` + ' ' + stl`md:block md:w-1/5`}>
              <Configure facets={['*']} maxValuesPerFacet={20} />

              <DynamicWidgets fallbackComponent={Menu} className={stl`md:mt-6`}>
                <Panel header="Domaine">
                  <RefinementList attribute="domain" />
                </Panel>
                <Panel header="Type de formation" className={stl`mt-4`} >
                  {/*<Menu attribute="training_category" />*/}
                  <RefinementList attribute="training_category" />
                </Panel>
              </DynamicWidgets>
              <div className={stl`mt-4 sm:mt-6`}>
                <CustomClearRefinements />
              </div>
            </div>

            <div className={stl`md:w-4/5 md:pl-12`}>

              <div className={(showFilters || showContentTypesSwitcher)?
                stl`bg-white absolute left-0 h-full w-full z-40 opacity-80 md:hidden` : ''}
                onClick={event => {
                  setShowFilters(false);
                  setShowContentTypesSwitcher(false);
                }}>
              </div>

              <div className={stl`space-y-6 mt-4`}>
                {(searchState.query)? contentTypes.map((contentType, i) => {
                    return (contentType.layout && (selectedTab == 0 || selectedTab == i))? (
                        <HitList
                          key={i}
                          type={contentType.layout}
                          expanded={selectedTab != 0}
                          indexName={contentType.index_name}
                          title={contentType.name}
                          link={contentType.external_link}
                          hideContent={contentType.hide_content}
                          hideBreadcrumb={contentType.hide_breadcrumb} />
                    ) : '';
                  }) : (<div className={stl`py-4 md:py-6`}>(empty state)</div>)}
              </div>

            </div>
          </div>
        </InstantSearch>
      </div>
    </div>
  );
}

export function Tag(props) {
  let name = (props.name == 'Conservation-restauration')? 'CR' : props.name;
  return (
    (props.size == 's')?
      <span className={stl`px-1 py-0 font-normal text-sm bg-white border border-accent text-accent`}>{name}</span>
      : <span className={stl`px-2 py-1 font-normal text-sm bg-white border border-accent text-accent`}>{name}</span>
  );
}

export function CardMeta(props) {
  let name = (props.name == 'Conservation-restauration')? 'CR' : props.name; // TODO: create a function
  return (
    (props.size == 's')?
      <span className={stl`py-0 text-secondary font-normal text-sm`}>{name}</span>
      : <span className={stl`py-1 font-bold text-md text-accent`}>{name}</span>
  );
}

export function Tab(props) {
  return (
    <li onClick={props.onClick} className={stl`mr-2`}>
      <span className={stl`w-full sm:w-auto inline-block hover:no-underline py-2 md:py-2 md:px-2 md:font-bold sm:text-center text-grey md:border-b-3 transition cursor-pointer` + ' '
        + ((props.active)? stl`md:border-accent md:text-accent active` : stl`border-transparent md:hover:border-accent md:hover:text-accent`)}>
          {props.name}
      </span>
    </li>
  );
}

let dateFormatter = new Intl.DateTimeFormat('fr-CH', {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
});

export function ymdToPrettyDate(ymd) {
  let fullDate = Array.from(ymd);
  if (fullDate.length == 8) {
    // Ymd
    return fullDate.splice(6,2).join('') + '.'
      + fullDate.splice(4,2).join('')+ '.'
      + fullDate.splice(2,4).join('');
  } else {
    // Y-m-d H:i:s
    let date = new Date(ymd);
    if (date instanceof Date) {
      return (!isNaN(date))? dateFormatter.format( date ) : null;
    }
  }

}

const DebouncedSearchBox = connectSearchBox(SearchBox)

const ClearRefinements = ({ items, refine }) => (
  <button onClick={() => refine(items)} disabled={!items.length} className="btn-primary">
    Réinitialiser
  </button>
);

const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);

const CurrentRefinements = ({ items, refine, createURL }) => (
  <ul className={stl`flex flex-col md:flex-row md:space-x-3`}>
    {items.map(item => (
      <li key={item.label} className={ stl`inline-flex align-bottom`} >
        {item.items ? (
          <React.Fragment>
            <ul className={stl`flex flex-wrap transform`}>
            <span className={stl`mt-0.5 mr-2`}>{item.label}</span>
              {item.items.map(nested => (
                <li key={nested.label} className={ domainColors[nested.label] || 'theme-black' }>
                  <a
                    className={stl`block px-2 py-0.5 md:py-1 mb-2 mr-2 bg-white font-normal text-sm border border-accent text-accent hover:no-underline`}
                    href={createURL(nested.value)}
                    onClick={event => {
                      event.preventDefault();
                      refine(nested.value);
                    }}
                  >
                    {nested.label} ✕
                  </a>
                </li>
              ))}
            </ul>
          </React.Fragment>
        ) : (
          <a
            href={createURL(item.value)}
            onClick={event => {
              event.preventDefault();
              refine(item.value);
            }}
          >
            {item.label}
          </a>
        )}
      </li>
    ))}
  </ul>
);

const CustomCurrentRefinements = connectCurrentRefinements(CurrentRefinements);


export default App;
